<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Screen_Printer/VERSAPRINT 2 PRO2.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    World's first stencil printer with integrated 3D inspection
                  </h2>
                  <br />
                  <h5>
                    With the fast 2D LIST camera (LIST = Line Scanning Technology), the VERSAPRINT 2 PRO² is particularly suitable for products with high inspection requirements. The system can be upgraded or retrofitted with all options of the VERSAPRINT 2 series.
                  </h5>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />

            <div>
              <div class="">
                <div class="">
                  <div>
                    <b-carousel
                      id="carousel-1"
                      v-model="slide"
                      :interval="2000"
                      controls
                      indicators
                      background="#fff"
                      style="text-shadow: 1px 1px 2px #333"
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd"
                    >
                      <div class="carousel-item" active>
                        <div class="parent d-flex justify-content-center">
                          <img
                            class="d-block img-fluid"
                            src="../../../../assets/images/product/main/production_equipement/Screen_Printer/versaprint 2 pro 2/slider/1.jpg"
                            alt="category.name"
                          />
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="parent d-flex justify-content-center">
                          <img
                            class="d-block img-fluid"
                            src="../../../../assets/images/product/main/production_equipement/Screen_Printer/versaprint 2 pro 2/slider/2.jpg"
                            alt="category.name"
                          />
                        </div>
                      </div>
                       <div class="carousel-item">
                        <div class="parent d-flex justify-content-center">
                          <img
                            class="d-block img-fluid"
                            src="../../../../assets/images/product/main/production_equipement/Screen_Printer/versaprint 2 pro 2/slider/3.jpg"
                            alt="category.name"
                          />
                        </div>
                      </div>
                    
                      
                    </b-carousel>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />

            <h1><strong> Technical data </strong></h1>
            <br /><br />
            <div class="row container" style="font-size: 12px">
              <div class="container-fluid">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row" style="width: 400px">
                          Substrate size (X x Y): min. 50 x 50 mm, max. <br>
                           680 x 500 mm (inspection area 550 x 500 mm)
                        </th>
                        <td style="font-size: 12px">
                         Print head: two independent squeegee <br>
                          heads with continuous squeegee force control, <br>
                           squeegee depth stop and pendulum limitation
                        </td>
                        <td style="font-size: 12px">
                          Cycle time: 10 s + pressure
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Optional stencil cleaner: 680 x 500 mm
                        </th>
                        <td style="font-size: 12px">Squeegee force: 0-230 N</td>
                        <td style="font-size: 12px">
                          Time to set up: less than 10 min
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Substrate thickness: 0.5-6 mm</th>
                        <td style="font-size: 12px">
                          Camera: 3D line scan camera for alignment <br>
                           and inspection of substrate and stencil
                        </td>
                        <td style="font-size: 12px">
                         Product changeover: under 2 min
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Part clearance: up to 35 mm</th>
                        <td style="font-size: 12px">
                          Repetition accuracy: +/-12.5 µm @ 6 Sigma
                        </td>
                        <td style="font-size: 12px">
                         Graphical user interface: touch screen
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Stencil size: min. 450 x 450 mm, max. <br>
                           737 x 737 mm adjustable without tools
                        </th>
                        <td style="font-size: 12px">
                          Printing accuracy: +/-25 µm @ 6 sigma
                        </td>
                        <td style="font-size: 12px">
                         
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br /><br />
                <!-- video -->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                      <div class="row">
                        <div class="col-xl-6">
                          <!-- 16:9 aspect ratio -->
                          <div class="ratio ratio-21x9">
                            <iframe
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/V1dOINVdwsc"
                              
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>

                        <div class="col-xl-4">
                          <h2>Ersa Stencil Printer - VERSAPRINT 2 ULTRA³</h2>
                          <h5>United in efficiency: The VERSAPRINT 2 ULTRA³ is the world's first stencil printer with integrated 3D inspection.</h5>
                        </div>
                        <!-- end col -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>

                <!-- end video-->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>